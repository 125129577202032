<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="faq_question" :translate-params="{id: question.id, name: question.question.it}">Edit Question  #%{id} - %{name}</translate>
    </h1>
    <QuestionForm
        :data="question"
        :autocomplete_tag="(query) => autocompleteTags({query: query, category: TagCategory.FAQ})"
        @save="onSave"
        :save_text="$pgettext('form','Save')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";
import QuestionForm from "@/domain/faq/components/QuestionForm.vue";
import {TagCategory} from "@/domain/core/utils/constants";

export default {
  name: 'EditFaqQuestion',
  components: {QuestionForm},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchQuestion(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'faqCategory.list'})
      }
    }
    this.loading = false
  },
  computed: {
    TagCategory() {
      return TagCategory
    },
    ...mapGetters('faq', [
      'question',
    ]),
  },
  methods: {
    ...mapActions('faq', [
      'fetchQuestion',
      'updateQuestion',
    ]),
    ...mapActions('core', [
      'autocompleteTags',
    ]),
    async onSave(data) {
      await this.updateQuestion({id: this.question.id,data: data})
      await this.$router.push({name: 'faqQuestion.list', params: {category: this.$route.params.category}})
      throw new SuccessNotification(this.$pgettext('faq_question', 'Faq Question updated!'))
    }
  }
}
</script>

<style scoped>

</style>